<script setup>
const emit = defineEmits(['closed']);

function sendEmit() {
  emit('closed', false);
}

const placeholder = '';
</script>

<template>
  <p-card id="all" class="ticket-card">
    <div class="info-bar">
      <div id="logo" />
      <h2>{{placeholder}}</h2>
      <img id="information"
           @click="sendEmit"
           @keydown.enter="sendEmit"
           src="/img/information.png"
           alt="information" />
    </div>
    <div id="first" style="padding-top: 0" class="content-placeholder">
      <div  class="kurve-info">
        <p style="font-weight: lighter">Ticket-ID</p>
        <p>664794476db01664f3105a4d</p>
      </div>
      <div class="kurve-info">
        <p style="font-weight: lighter">Order-ID</p>
        <p>2416318</p>
      </div>
      <div class="kurve-info">
        <p style="font-weight: lighter">Preis</p>
        <p>15,00 €</p>
      </div>
      <div class="kurve-info">
        <p style="font-weight: lighter">Spielstätte</p>
        <p>Deutsche Bank Park</p>
      </div>
      <div id="info">
        <h3>Kontakt</h3>
        <div class="contact">
          <img id="letter" src="/img/letter.png" alt="letter" /> <p>info@eintrachtfrankfurt.de</p>
        </div>
      </div>
      <div class="street">
        <p>Im Herzen von Europa 1</p>
        <p>60528 Frankfurt am Main</p>
      </div>
      <p style="font-weight: normal; text-decoration: underline">www.eintracht.de</p>
      <div class="foot">
        <p><span>AGB</span> und <span>Stadionordnung</span></p>
      </div>
    </div>
  </p-card>
</template>

<style scoped>
span {
  text-decoration: underline;
}

#all {
  font-family: Roboto, serif;
}

#logo {
  width: 40px;
  height: 40px;
}

#information {
  padding-top: 10px;
  filter: invert(.45) !important;
}

#first {
  margin-top: -10% !important;
}

#letter {
  width: 25px;
  height: 30px;
  color: red;
}

#info {
  padding-top: 1em;
}

.contact {
  margin-top: -2%;
  display: flex;
  color: #c72929;
  font-weight: bold;
  text-align: center;
  align-items: center;

  img {
    padding-right: .75%;
    filter: invert(.004) !important;
  }

  p {
    font-size: 1.15em;
    padding-left: 2%;
    padding-bottom: 5px;
  }
}

.content-placeholder {
  font-size: 16px;
}

.kurve-info {
  padding-top: 1em;
  font-weight: bold;
}

.street {
  padding-top: 5%;
  font-size: 16px;
  display: block;
  padding-bottom: 1%;
}

.foot {
  font-size: 16px;
  font-weight: lighter;
  padding-top: 18.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
