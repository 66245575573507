import './registerServiceWorker';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { createApp } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import store from './store';
import router from './router';
import App from './App.vue';

import 'primeicons/primeicons.css';

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(PrimeVue, { theme: { preset: Aura } })
  .component('Dialog', Dialog)
  .component('Button', Button)
  .component('InputText', InputText)
  .mount('#app');
