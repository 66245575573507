<!-- eslint-disable -->
<template>
  <div class="settings-card">
    <div class="settings-header">
      <img src="/img/aqila.png" alt="main aqila" class="settings-logo" />
    </div>
    <div class="settings-content" @scroll="handleScroll">
      <ul class="settings-list">
        <li>START</li>
        <li>EINTRACHT</li>
        <li>DEUTSCHE BANK PARK</li>
        <li>NEWS AUS DER REGION</li>
        <li>SHOPS</li>
      </ul>
      <ul class="settings-items">
        <li><img src="/img/icons/wallet.png" alt="wallet" class="item-icon"/> Wallet</li>
        <li><img src="/img/icons/cart.png" alt="cart" class="item-icon"/> Warenkorb</li>
        <li><img src="/img/icons/pay.png" alt="pay" class="item-icon"/> mainpay</li>
        <li><img src="/img/icons/heart.png" alt="favorites" class="item-icon"/> Favoriten</li>
        <li><img src="/img/icons/man.png" alt="profile" class="item-icon"/> Profil und Einstellungen</li>
      </ul>
      <p id="imprint">Impressum <span class="dot">■</span> Datenschutz <span class="dot">■</span> AGB</p>
    </div>
    <div class="settings-footer">
      <div class="footer-item placeholder"></div>
      <div class="footer-item" id="home-parent">
        <img id="home" src="/img/icons/start.png" alt="start" class="footer-icon" /> Start
      </div>
      <div class="footer-item" @click="$emit('close')">
        <img id="close" src="/img/icons/close.png" alt="close" class="footer-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsComponent',
  emits: ['close'],
  methods: {
    handleScroll(event) {
      if (event.target.scrollTop === 0.1) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.settings-card {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1f1f1f;
  color: #e0c080;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', sans-serif;
  overflow-y: auto;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.settings-header {
  padding: 20px;
  text-align: center;
}

.settings-logo {
  width: 35%;
  height: auto;
}

.settings-content {
  padding: 0 20px;
  overflow-y: auto;
}

.settings-list {
  font-weight: bold;
  list-style: none;
  padding: 0;
  margin: 0;
}

.settings-list li:first-child {
  padding-top: 0;
}

.settings-list li {
  padding: 15px 0;
  font-size: 1.8rem;
  color: #fff;
}

.settings-items {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.settings-items li {
  display: flex;
  align-items: center;
  padding: 20px 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #807559;
}

.item-icon {
  width: 9%;
  height: auto;
  margin-right: 15px;
}

.settings-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: #2a2a2a;
  border-top: 1px solid #444;
}

.settings-footer .footer-item {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
  color: #807559;
}

.settings-footer .footer-icon {
  width: 15%;
  height: auto;
  margin-right: 2px;
  text-align: center;
}

#close {
  margin-bottom: 5px;
  width: 20%;
}

#home {
  width: 20%;
  margin-bottom: 5px;
}

#home-parent {
  font-size: 1rem;
  margin-left: 50px;
}

.settings-content::-webkit-scrollbar {
  display: none;
}

.settings-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#imprint {
  color: #807559;
  font-weight: bold;
  font-size: 1.1rem;
}

.dot {
  font-size: .25rem;
  margin-bottom: 1rem;
}
</style>
